export const en = {
    btn: {
        getConsultation: 'Contact us', // Обычно это "Contact us"
        more: 'Read More',
        send: 'Send',
        showMore: 'Show More',
        hide: 'Hide',
        close: 'Close'
    },

    form: {
        title: 'Get a consultation',
        text: 'We will contact you to advise on all matters',
        placeholder: {
            name: 'How to address you*',
            email: 'E-mail*',
            phone: 'Phone Number*',
            message: 'Write your message'
        },
        required: 'Please fill in this field', // *
        requiredAll: 'Fill in the fields to send',
        description: 'Pressing «Send» you consent to the processing of your personal data ',
        contactUs: 'We will contact you soon',
        weWillCall: 'Your request has been received. We will call you back during the working day',
        error: 'Form submission error, try again',
    },

    schema: {
        required: 'Required field',
        email: "Invalid email",
        phone: "Invalid number. Enter 8 instead of +7 at the beginning"
    },

    //------index------

    header: {
        title: 'Computer vision-based services',  // Computer vision-based services
        subtitle: 'We create complex solutions for automation and security enhancement based on biometrics',
    },

    services: {
        title: 'Services',
        subtitle: 'Automatization of access to the territory', // Automatization of access to the territory
        text: 'Face2Pass service uses facial recognition of employees to control access to the facility',
        benefitsList: [
            {
                text: 'Vehicle parking access control'
            },
            {
                text: 'Enhancement the security of data storage according to Federal Law NO. 152-FZ'
            },
            {
                text: 'Increased speed of the facilities pass entry system'
            },
            {
                text: 'Integration with employee monitoring software system and PACS'
            },
        ],
        benefitsItemOne: 'Supervise vehicle access to the parking lot', // Vehicle parking access control
        benefitsItemTwo: 'Enhancement of data storage security under FZ-152', // Enhancement the security of data storage according to Federal Law NO. 152-FZ
        benefitsItemThree: 'Increased speed of the facilities pass entry system',
        benefitsItemFour: 'Integration with attendance registration system and PACS', // Integration with employee monitoring software system and PACS
        servicesList: [
            {
                name: {
                    nameOne: 'Face',
                    nameTwo: 'Pay',
                    imageUrl: '/face2redLogo.svg',
                    imageAlt: 'face2Pay logo'
                },
                title: `Payment system based on facial recognition`,
                text: 'Allows' +
                    // "Ак Барс Банка" +
                    'clients to pay for goods and services via facial recognition',
                link: '/pay'
            },
            {
                name: {
                    nameOne: 'Face',
                    nameTwo: 'Security',
                    imageUrl: '/face2darkBlueLogo.svg',
                    imageAlt: 'face2Security logo'
                },
                title: `Biometric workplace security control`,
                text: 'Eliminates the possibility of gaining access to unauthorized workstations and lets users use biometrics to confirm important transactions',
                link: '/security'
            },
            {
                name: {
                    nameOne: 'Face',
                    nameTwo: 'Loyalty',
                    imageUrl: '/face2orangeLogo.svg',
                    imageAlt: 'face2Loyalty logo'
                },
                title: `Loyalty system based on facial recognition`,
                text: 'Allows for personalized terms and discounts without the use of plastic cards',
                link: '/loyalty'
            }
        ],
    },

    control: {
        title: 'One control point of operation - many points of application', // One control point
        text: 'Synchronization with popular PACS provides maximum convenience for system users and operators and integration with cash register system allows you to create a smooth user experience of interaction with customers', // Synchronization with popular ACS provides maximum convenience for system users and operators and integration with cash register system allows you to create a smooth user experience of interaction with customers
        description: {
            title: 'A single point of registration and employee and customer management',
            subtitle: 'Available Features:',
            list: ['Access to the territory, facility', 'Parking access control', 'Payment at a cash register', 'access to the workstation', 'Partner discount programs'],
            //      "Access to the territory, facility", "Parking access control"                   " access to the workstation"
        }
    },

    aboutAs: {
        title: 'Read about us',
        list: [

            {
                src: '/kommersant-logo.png',
                alt: 'kommersant',
                link: {
                    url: 'https://www.kommersant.ru/doc/5131024',
                    name: 'kommersant.ru',
                },
                text: 'Ak Bars Bank became a Retail Finance Awards 2021 award winner',
            },
            {
                src: '/abb-site-logo.svg',
                alt: 'Ak Bars Bank',
                link: {
                    url: 'https://www.akbars.ru/news/ak-bars-bank-zapustil-sistemu-avtorizatzii-na-rabochih-mestah/',
                    name: 'akbars.ru',
                },
                text: 'Ak Bars Bank launched an authorization system at workplaces named Face2Security',
            },
            {
                src: '/abb-site-logo.svg',
                alt: 'Ak Bars Bank',
                link: {
                    url: 'https://www.akbars.ru/news/ak-bars-bank-podvyel-itogi-foruma-kazan-digital-week/',
                    name: 'akbars.ru',
                },
                text: 'Ak Bars Bank has concluded the "Fintech Ecosystem" section of the Kazan Digital Week forum',
            },
            {
                src: '/abb-site-logo.svg',
                alt: 'Ak Bars Bank',
                link: {
                    url: 'https://www.akbars.ru/news/ak-bars-bank-podvyel-itogi-foruma-kazan-digital-week/',
                    name: 'akbars.ru',
                },
                text: 'Ak Bars Retro Cars exhibition complex opened in Kazan',
            },
            // {
            //     src:'/abb-site-logo.svg',
            //     alt: 'Ак барс банк',
            //     link: {
            //         url: 'https://www.akbars.ru/news/v-kazani-otkrilsya-vistavochnii-kompleks-retroavtomobilei-ak-bars-retro-cars',
            //         name:'akbars.ru',
            //     },
            //     text:'В Казани открылся выставочный комплекс ретроавтомобилей Ak\u00A0Bars\u00A0Retro\u00A0Cars',
            // },
        ],
    },

    terminal: {
        title: 'Terminals',
        list: [
            {
                title: 'INOFACE',
                text: 'Terminal for automating on-site entry through biometrics',
            },
            {
                title: 'INOFACE 13”',
                text: 'Terminal for payments and bio-acquiring',
            },
            {
                title: 'INOFACE PARK-1',
                text: 'Terminal for the automation of vehicle access to the parking lot',
            },
        ],
        certification: {
            text: 'Terminals certified in compliance with GOST 30805.13-2013, GOST R (Russian National Standard) 51318.20-2-12 and meets the requirements of TR CU 020/2011',
        }

    },

    partners: {
        title: 'Opportunities for partners',
        list: [
            {
                src: '/cloud-icon.svg',
                alt: 'cloud icon',
                title: 'A cloud-based solution',
                text: 'Face2Pass, Face2Pay and Face2Loyalty services are available online',
            },
            {
                src: '/integration-icon.svg',
                alt: 'integration icon',
                title: 'Integrations',
                text: 'Services can be integrated with any PACS and security systems',
            },
            {
                src: '/lock-icon.svg',
                alt: 'security icon',
                title: 'Secure like in a bank',
                text: 'High standards of data storage and processing',
            },
        ],
        documentation: {
            title: 'Documentation',
            list: [
                {
                    title: 'User manual',
                    link: '/docs/user_manual.pdf'
                },
                {
                    title: 'Installation Guide',
                    link: '/docs/setup_guide.pdf'
                },
                {
                    title: 'Functional specifications',
                    link: '/docs/functional_characteristics.pdf'
                },
                {
                    title: 'License Agreement',
                    link: '/docs/licenseAgreement.pdf'
                },
                // {
                //     title: 'Frequently Asked Questions',
                //     link: '/docs/faq.pdf'
                // },
            ]
        },
        description: 'The service is developed on the basis of machine learning. Every time a user passes a terminal, the service memorizes their appearance and adds it to the database.',
    },

    purchase: {
        title: 'Purchasing options',
        oneTimePayment: {
            title: 'Single payment', // "single payment"
            text: 'Payment is made once after equipment has been installed and debugged. Maintenance and replacement of equipment will not be performed.'
        },
        serviceModel: {
            title: 'Service model payment', // "Service model payment"
            text: 'Payment to be made on a regular basis. The equipment will be installed and debugged. Afterwards, we will perform maintenance, support and replacement of the equipment.'
        },
        description: 'Full cycle of operations from project development and cost estimates to commissioning and maintenance'
    },

    applications: {
        title: 'Applications of biometric facial recognition technology',
        list: [
            {
                src: '/bank_icon.svg',
                title: 'Government agencies',
                list: ['The pass-entry system', 'Workplace discipline management', 'Control of access to secured information']
            },
            {
                src: '/book_icon.svg',
                title: 'Educational institutions',
                list: ['The pass-entry system', 'The record of entry and exit times', 'Meals with face authorization']
            },
            {
                src: '/rouble_circle_icon.svg',
                title: 'Monetary organizations',
                list: ['Authorization of employees at workstations', 'Confirmation of important transactions using biometrics']
            },
            {
                src: '/home_icon.svg',
                title: 'Residential apartment complexes',
                list: ['Resident access system', 'Pass entry system for vehicles by license plate numbers']
            },
            {
                src: '/profile_icon.svg',
                title: 'Secure facilities',
                list: ['Multilevel pass entry system', 'Access management and control']
            },
            {
                src: '/shop_icon.svg',
                title: 'Shopping, entertainment and hotel complexes',
                list: ['Contactless service payment', 'Access for visitors and staff']
            },
            {
                src: '/bus_icon.svg',
                title: 'Transport companies',
                list: ['Contactless fare payment', 'Passenger flow control', 'Monitor employee access to high security facilities']
            },
            {
                src: '/car_icon.svg',
                title: 'Car parking',
                list: ['Contactless access by license plate number or biometrics', 'Registration of entries and exits']
            },
        ]
    },

    completedProject: {
        title: 'Implemented projects',
        list: [
            {
                src: '/project-akb.svg',
                alt: 'logo Ak Bars',
                text: 'Access control system in an office building',
                // text: 'Access control system in "Ak Bars Bank" office',
            },
            {
                src: '/project-cava.svg',
                alt: 'Logo cava',
                text: 'A facial recognition payment system in the Cava chain coffee shops',
            },
            {
                src: '/project-tech-park-inn.svg',
                alt: 'Logo Innopolis',
                text: 'Available central entrance to the Popov Technopark (Innopolis city)',
            },
        ],
        listText: ['Collection of biometric data is in the office of Center Credit bank (Kazakhstan, Almaty)', 'Time tracking at "Timerkhan" Restaurant (Kazan)', 'Visitor management with a face identification system in the State Council building of the Republic of Tatarstan'],
        moreList: [
            {
                src: '/project-kindergarten187.jpg',
                alt: 'Kindergarten No. 187',
                text: 'Security system at the entrance to Kindergarten No. 187 (Kazan)',
            },
            {
                src: '/project-transport-museum.jpg',
                alt: 'RT Transport Museum',
                text: 'Payment of tickets by person at the Museum of Transport of the Republic of Tatarstan',
            },
            {
                src: '/project-it-park.jpg',
                alt: 'IT-park',
                text: 'The main entrance to the central office of the technopark "IT-park" (Kazan)',
            },
        ],
        moreListText: [
            '"Pilot" of the face-to-face payment system in the cafeteria of Gymnasium No. 19 (Kazan)', '"Pilot" of the face-to-face fare system in Kazan trams', 'Automated parking system by face in an office building'
        ]
    },

    contacts: {
        title: 'Contacts',
        formPlaceholder: {
            name: 'How to address you*',
            email: 'E-mail*',
            phone: 'Phone Number*',
            message: 'Write your message'
        },
        required: 'Please fill in this field',
        description: 'Pressing «Send» you consent to the processing of your personal data' // By clicking
    },

    footer: {
        contacts: 'Contacts'
    },

    //-----other pages------

    face2pass: {
        header: {
            title: 'Automation of on-site entry through biometrics',
            subtitle: 'Face2Pass service uses facial recognition of employees to control on-site access',
        },

        videoSection: {
            description: 'Face2Pass is an access control system based on facial recognition'
        },

        advantageSection: {
            list: [
                {
                    title: 'Vehicle parking access control', // "Vehicle parking access control"
                    text: 'The additional terminal controls the access by license plate number or by facial recognition of the driver.'
                },
                {
                    title: 'Increased speed of the facilities pass entry system in a facility',
                    text: ''
                },
                {
                    title: 'Enhancement the security of data storage according to Federal Law NO. 152-FZ', // Enhancement the security of data storage according to Federal Law NO. 152-FZ
                    text: ''
                },
                {
                    title: 'Integration with attendance registration system and PACS', // По идее везде вместо ACS -> PACS
                    text: ''
                },
            ]
        },

        applications: {
            title: 'Applications of biometric facial recognition technology',
            list: [
                {
                    title: 'Government agencies',
                    list: ['The pass-entry system', 'Workplace discipline management', 'Control of access to secured information']
                },
                {
                    title: 'Educational institutions',
                    list: ['The pass-entry system', 'The record of entry and exit times', 'Meals at no charge']
                },
                {
                    title: 'Monetary organizations',
                    list: ['Authorization of employees at workstations', 'Confirmation of important transactions using biometrics']
                },
                {
                    title: 'Residential apartment complexes',
                    list: ['Resident access system', 'Pass entry system for vehicles by license plate numbers']
                },
                {
                    title: 'Secure facilities',
                    list: ['Various levels of pass entry system', 'Access management and control']
                },
                {
                    title: 'Shopping, entertainment and hotel complexes',
                    list: ['Contactless service payment', 'Access for visitors and staff']
                },
                {
                    title: 'The transportation infrastructure',
                    list: ['Contactless fare payment', 'Passenger flow control', 'Monitor employee access to high security facilities']
                },
                {
                    title: 'Parking lots',
                    list: ['Contactless access by license plate number or biometrics', 'Registration of entries and exits']
                },
            ]
        },

        terminal: {
            title: 'Terminals',
            list: [
                {
                    title: 'INOFACE',
                    text: 'Terminal for automating on-site entry through biometrics',
                },
                {
                    title: 'INOFACE PARK-1',
                    text: 'Terminal for the automation of vehicle access to the parking lot',
                },
            ],
        },

        ControlTheSystem: {
            title: 'Manage your system from anywhere in the world',
            description: 'You can log into the PACS program from any computer with internet access. Just remember your username and password',
            list: [
                {
                    text: 'Track employee activity with a pass log',
                    src: '/time-icon.svg'
                },
                {
                    text: 'Have access to information on all failed entry attempts, including a photo',
                    src: '/error-circle-icon.svg'
                },
                {
                    text: 'Issue temporary and visitor passes',
                    src: '/profile_icon.svg'
                },
                {
                    text: 'Set up groups of employees with individual access permissions',
                    src: '/group-user-icon.svg'
                }
            ]
        }
    },

    face2pay: {
        header: {
            subtitle: 'A biometric bank acquiring system enabling payment for goods and services using facial recognition',
        },

        videoSection: {
            description: 'Payment via the Face2Pay service'
        },

        advantageSection: {
            list: [
                {
                    title: 'Speeds up checkout service by up to 30%'
                },
                {
                    title: 'Reduces bank acquiring costs'
                },
                {
                    title: 'Full compliance with FZ-54 "On application of cash registers"'
                },
                {
                    title: 'Personalizes advertisements on the terminal screen'
                },
            ]
        },

        usedTerminal: {
            title: 'Terminal in use',
            description: 'Each point of sale must be equipped with at least one terminal'
        }
    },

    face2Security: {
        header: {
            subtitle: 'Supervise workplace security and users productivity in front of the computer using facial biometrics',
        },

        videoSection: {
            description: 'How the Faсe2Security system works'
        },

        advantageSection: {
            list: [
                {
                    title: 'User authorization at workstations'
                },
                {
                    title: 'Confirmation of important transactions using biometrics'
                },
                {
                    title: 'Integration with security systems'
                },
                {
                    title: 'Elimination of password prying and mining'
                },
                {
                    title: 'Log-book maintanence and prevention of unauthorized access to the workstation'
                },
            ]
        },

        usedTerminal: {
            title: 'Equipment',
            description: 'All that is needed to use \nFace2Security is the computers built-in webcam'
        }


    },

    face2Loyalty: {
        header: {
            subtitle: 'Face recognition based loyalty program',
        },

        videoSection: {
            description: 'Use in Cava Cafe'
        },

        advantageSection: {
            list: [
                {
                    title: 'Increase in speed of service',
                    text: 'There is no form to fill out when registering, just a look into the camera'
                },
                {
                    title: 'High registration speed',
                    text: 'A customer does not waste time presenting a card, but simply looks into the camera'
                },
                {
                    title: 'Get connected quickly', // "easy to use"?
                    text: 'Fits most POS terminals such as iiko and r-keeper'
                },
            ]
        },

        usedTerminal: {
            title: 'Equipment',
            list: [
                {
                    title: 'If you have a \nFace2Pay.',
                    text: 'The program connects to a biometric terminal',
                },
                {
                    title: 'If you are not connected to \nFace2Pay',
                    text: 'Any tablet is compatible to work Face2Loyalty',
                },
            ]
        }
    },

    errorPage: {
        title: 'The page was not found',
        text: 'Unfortunately, this page does not exist. \nIt must have been deleted or it was never there',
        goBack: 'Go back'
    },
};