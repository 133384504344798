export const ru = {
    btn: {
        getConsultation: 'Получить консультацию',
        more: 'Подробнее',
        send: 'Отправить',
        showMore: 'Показать еще',
        hide: 'Скрыть',
        close: 'Закрыть'
    },

    form: {
        title: 'Получить консультацию',
        text: 'Мы свяжемся с вами чтобы проконсультировать по всем вопросам',
        placeholder: {
            name: 'Как к вам обращаться*',
            email: 'E-mail*',
            phone: 'Телефон*',
            message: 'Напишите ваше сообщение'
        },
        required: 'Заполните это поле',
        requiredAll: 'Заполните поля для отправки',
        description: 'Нажимая «Отправить» вы даёте согласие на обработку персональных данных',
        contactUs: 'Скоро свяжемся с вами',
        weWillCall: 'Ваше обращение получено. Мы перезвоним в течение рабочего дня',
        error: 'Ошибка отправки формы, попробуйте снова',
    },

    schema: {
        required: 'Обязательное поле',
        email: "Не валидный email",
        phone: "Не валидный номер. Введите 8, вместо +7 в начале"
    },

    //------index------

    header: {
        title: 'Сервисы на основе машинного зрения',
        subtitle: 'Создаём комплексные решения для автоматизации и\u00A0усиления безопасности на основе биометрии',
    },

    services: {
        title: 'Сервисы',
        subtitle: 'Автоматизация доступа на территорию',
        text: 'Сервис Face2Pass распознаёт сотрудников по лицу, обеспечивая контроль доступа на объект',
        benefitsList: [
            {
                text: 'Контроль доступа автомобилей на парковку'
            },
            {
                text: 'Усиление безопасности хранения данных по ФЗ-152'
            },
            {
                text: 'Увеличение скорости пропускной системы объекта'
            },
            {
                text: 'Интеграция с системами учёта рабочего времени и СКУД'
            },
        ],
        servicesList:  [
            {
                name: {
                    nameOne: 'Face',
                    nameTwo: 'Pay',
                    imageUrl: '/face2redLogo.svg',
                    imageAlt: 'face2Pay logo'
                },
                title: `Платёжная система с оплатой по\u00A0лицу`,
                text: 'Позволяет клиентам' +
                    // "Ак Барс Банка" +
                    ' оплачивать товары и услуги с помощью лица',
                link: '/pay'
            },
            {
                name: {
                    nameOne: 'Face',
                    nameTwo: 'Security',
                    imageUrl: '/face2darkBlueLogo.svg',
                    imageAlt: 'face2Security logo'
                },
                title: `Контроль безопасности рабочего места по\u00A0биометрии`,
                text: 'Исключает доступ к чужому рабочему месту и даёт возможность подтверждать важные операции с помощью биометрии',
                link: '/security'
            },
            {
                name: {
                    nameOne: 'Face',
                    nameTwo: 'Loyalty',
                    imageUrl: '/face2orangeLogo.svg',
                    imageAlt: 'face2Loyalty logo'
                },
                title: `Система лояльности с распознаванием по\u00A0лицу`,
                text: 'Позволяет получать персонализированные условия и скидки без использования пластиковых карт',
                link: '/loyalty'
            }
        ],
        cardOne: {
            title: 'Платёжная система с оплатой по лицу',
            text: 'Позволяет клиентам Ак Барс Банка оплачивать товары и услуги с помощью лица'
        },
        cardTwo: {
            title: 'Контроль безопасности рабочего места по биометрии',
            text: 'Исключает доступ к чужому рабочему месту и даёт возможность подтверждать важные операции с помощью биометрии'
        },
        cardThree: {
            title: 'Система лояльности с распознаванием по лицу',
            text: 'Позволяет получать персонализированные условия и скидки без использования пластиковых карт'
        },
    },

    control: {
        title: 'Одна точка управления – много точек использования',
        text: 'Синхронизация с популярными СКУД позволяет обеспечить максимальное удобство для пользователей и операторов системы, а интеграция с кассовыми решениями позволяет создавать бесшовный пользовательский опыт взаимодействия с клиентами',
        description: {
            title: 'Единая точка регистрации и управления сотрудниками и клиентами',
            subtitle: 'Доступ к возможностям:',
            list: ['Вход на территорию', 'Въезд на парковку', 'Оплата на кассе', 'Разблокировка рабочего места', 'Получение скидок у партнёров'],
        }
    },

    aboutAs: {
        title: 'О нас пишут',
        list: [

            {
                src: '/kommersant-logo.png',
                alt: 'Коммерстант',
                link: {
                    url: 'https://www.kommersant.ru/doc/5131024',
                    name: 'kommersant.ru',
                },
                text: 'Ак Барс Банк стал лауреатом премии Retail Finance Awards 2021',
            },
            {
                src: '/abb-site-logo.svg',
                alt: 'Ак барс банк',
                link: {
                    url: 'https://www.akbars.ru/news/ak-bars-bank-zapustil-sistemu-avtorizatzii-na-rabochih-mestah/',
                    name: 'akbars.ru',
                },
                text: 'Ак Барс Банк запустил систему авторизации на рабочих местах Face2Security',
            },
            {
                src: '/abb-site-logo.svg',
                alt: 'Ак барс банк',
                link: {
                    url: 'https://www.akbars.ru/news/ak-bars-bank-podvyel-itogi-foruma-kazan-digital-week/',
                    name: 'akbars.ru',
                },
                text: 'Ак Барс Банк подвёл итоги секции «Экосистема финтех» форума Kazan Digital Week',
            },
            {
                src: '/abb-site-logo.svg',
                alt: 'Ак барс банк',
                link: {
                    url: 'https://www.akbars.ru/news/ak-bars-bank-podvyel-itogi-foruma-kazan-digital-week/',
                    name: 'akbars.ru',
                },
                text: 'В Казани открылся выставочный комплекс ретроавтомобилей Ak\u00A0Bars\u00A0Retro\u00A0Cars',
            },
            // {
            //     src:'/abb-site-logo.svg',
            //     alt: 'Ак барс банк',
            //     link: {
            //         url: 'https://www.akbars.ru/news/v-kazani-otkrilsya-vistavochnii-kompleks-retroavtomobilei-ak-bars-retro-cars',
            //         name:'akbars.ru',
            //     },
            //     text:'В Казани открылся выставочный комплекс ретроавтомобилей Ak\u00A0Bars\u00A0Retro\u00A0Cars',
            // },
        ],
    },

    terminal: {
        title: 'Терминалы',
        list: [
            {
                title: 'INOFACE',
                text: 'Терминал для автоматизации доступа на объект по биометрии',
            },
            {
                title: 'INOFACE 13”',
                text: 'Терминал для платежей и биоэквайринга',
            },
            {
                title: 'INOFACE PARK-1',
                text: 'Терминал для автоматизации доступа автомобилей на парковку',
            },
        ],
        certification: {
            text: 'Терминалы сертифицированные в соответствии с ГОСТ 30805.13-2013, ГОСТ Р 51318.20-2-12 и соответствует требованиям ТР ТС 020/2011',
        }

    },

    partners: {
        title: 'Возможности для партнёров',
        list: [
            {
                src: '/cloud-icon.svg',
                alt: 'cloud icon',
                title: 'Облачное решение',
                text: 'Сервисы Face2Pass, Face2Pay и Face2Loyalty подключаются онлайн',
            },
            {
                src: '/integration-icon.svg',
                alt: 'integration icon',
                title: 'Интеграции',
                text: 'Сервисы можно интегрировать с любыми системами СКУД и охраны',
            },
            {
                src: '/lock-icon.svg',
                alt: 'security icon',
                title: 'Надёжно как в банке',
                text: 'Высокие стандарты хранения и обработки данных',
            },
        ],
        documentation: {
            title: 'Документация',
            list: [
                {
                    title: 'Инструкция пользователя',
                    link: '/docs/user_manual.pdf'
                },
                {
                    title: 'Руководство по установке',
                    link: '/docs/setup_guide.pdf'

                },
                {
                    title: 'Функциональные характеристики',
                    link: '/docs/functional_characteristics.pdf'
                },
                {
                    title: 'Лицензионное соглашение',
                    link: '/docs/licenseAgreement.pdf'
                },
                // {
                //     title: 'Часто задаваемые вопросы',
                //     link: '/docs/faq.pdf'
                // },
            ]
        },
        description: 'Сервис разработан на основе машинного обучения. Каждый раз, когда пользователь проходит терминал, сервис запоминает его внешний вид и добавляет в базу данных.',
    },

    purchase: {
        title: 'Варианты приобретения',
        oneTimePayment: {
            title: 'Единовременный платёж',
            text: 'Оплата производится один раз после подключения и отладки оборудования. Техническое обслуживание и замена оборудования производиться не будет.'
        },
        serviceModel: {
            title: 'Сервисная модель',
            text: 'Оплата будет производиться регулярно. Будет произведена установка и отладка оборудования. После этого мы будем производить техническое обслуживание, поддержку и замену оборудования.'
        },
        description: 'Полный цикл работ от разработки проекта и бюджетирования до пусконаладки и сопровождения'
    },

    applications: {
        title: 'Сферы применения технологии биометрических систем распознавания лиц',
        list: [
            {
                src: '/bank_icon.svg',
                title: 'Государственные учреждения',
                list: [
                    'Система пропусков',
                    'Учёт рабочей дисциплины',
                    'Контроль доступа к защищённой информации'
                ]
            },
            {
                src: '/book_icon.svg',
                title: 'Образовательные учреждения',
                list: [
                    'Пропускная система',
                    'Учёт времени входа и выхода',
                    'Оплата питания'
                ]
            },
            {
                src: '/rouble_circle_icon.svg',
                title: 'Денежно-кредитные организации',
                list: [
                    'Авторизация сотрудников на рабочих местах',
                    'Подтверждение важных операций с помощью биометрии'
                ]
            },
            {
                src: '/home_icon.svg',
                title: 'Жилые многоквартирные комплексы',
                list: [
                    'Система доступа жильцов',
                    'Пропускная система для автомобилей по гос. номерам'
                ]
            },
            {
                src: '/profile_icon.svg',
                title: 'Режимные объекты',
                list: [
                    'Разноуровневая пропускная система',
                    'Контроль и учёт доступа'
                ]
            },
            {
                src: '/shop_icon.svg',
                title: 'Торговые, развлекательные, гостиничные комплексы',
                list: [
                    'Бесконтактная оплата услуг',
                    'Доступ посетителей и сотрудников территории'
                ]
            },
            {
                src: '/bus_icon.svg',
                title: 'Транспортная инфраструктура',
                list: [
                    'Бесконтактная оплата проезда',
                    'Учёт пассажиропотока',
                    'Контроль доступа сотрудников к объектам особой безопасности'
                ]
            },
            {
                src: '/car_icon.svg',
                title: 'Автомобильные парковочные стоянки',
                list: [
                    'Бесконтактный доступ по гос. номеру или биометрии',
                    'Учёт въездов и выездов'
                ]
            }
        ]
    },

    completedProject: {
        title: 'Реализованные проекты',
        list: [
            {
                src: '/project-akb.svg',
                alt: 'Логотип ак барс',
                text: 'Система контроля доступа в офисном здании',
                // text: 'Система контоля доступа в офисе “Ак Барс” Банка',
            },
            {
                src: '/project-cava.svg',
                alt: 'Логотип cava',
                text: 'Система оплаты по лицу в сети кофейн Cava',
            },
            {
                src: '/project-tech-park-inn.svg',
                alt: 'Логотип иннополис',
                text: 'Центральный вход в технопарк им. Попова (г. Иннополис)',
            },
        ],
        listText: ['Система сбора биометрических данных в офисе Center Credit bank (Казахстан, Алматы)', 'Учёт рабочего времени в ресторане «Тимерхан» (г. Казань)', 'Контроль посетителей с системой идентификации лиц в здании Государственного совета РТ'],
        moreList: [
            {
                src: '/project-kindergarten187.jpg',
                alt: 'Детский сад №187',
                text: 'Система безопасности на входе в Детский сад №187 (г. Казань)',
            },
            {
                src: '/project-transport-museum.jpg',
                alt: 'Музей транспорта РТ',
                text: 'Оплата билетов по лицу в Музее Транспорта РТ',
            },
            {
                src: '/project-it-park.jpg',
                alt: 'IT-park',
                text: 'Главный вход в центральный офис технопарка «IT-park» (г. Казань)',
            },
        ],
        moreListText: [
            '«Пилот» системы оплаты по лицу в столовой Гимназии №19 (г. Казань)' , '«Пилот» системы оплаты за проезд по лицу в трамваях г. Казани', 'Автоматизированная парковочная система по лицу в офисном здании'
        ]
    },

    contacts: {
        title: 'Контакты',
        formPlaceholder: {
            name: 'Как к вам обращаться*',
            email: 'E-mail*',
            phone: 'Телефон*',
            message: 'Напишите ваше сообщение'
        },
        required: 'Заполните это поле',
        description: 'Нажимая «Отправить» вы даёте согласие на обработку персональных данных'
    },

    footer: {
        contacts: 'Контакты'
    },

    //-----other pages------

    face2pass: {
        header: {
            title: 'Автоматизация доступа на территорию по биометрии',
            subtitle: 'Сервис Face2Pass распознаёт сотрудников по лицу, обеспечивая контроль доступа на объект',
        },

        videoSection: {
            description: 'Face2Pass - Система контроля доступа на основе распознавания лица'
        },

        advantageSection: {
            list: [
                {
                    title: 'Контроль доступа автомобилей на парковку',
                    text: 'Дополнительный терминал контролирует пропуск по гос. номеру или распознаванию лица водителя'
                },
                {
                    title: 'Увеличение скорости пропускной системы объекта',
                    text: ''
                },
                {
                    title: 'Усиление безопасности и хранение данных по ФЗ-152',
                    text: ''
                },
                {
                    title: 'Интеграция с системами учёта рабочего времени и СКУД',
                    text: ''
                },
            ]
        },

        applications: {
            title: 'Сферы применения технологии биометрических систем распознавания лиц',
            list: [
                {
                    title: 'Государственные учреждения',
                    list: ['Система пропусков', 'Учёт рабочей дисциплины', 'Контроль доступа к защищённой информации']
                },
                {
                    title: 'Образовательные учреждения',
                    list: ['Пропускная система', 'Учёт времени входа и выхода', 'Оплата питания']
                },
                {
                    title: 'Денежно-кредитные организации',
                    list: ['Авторизация сотрудников на рабочих местах', 'Подтверждение важных операций с помощью биометрии']
                },
                {
                    title: 'Жилые многоквартирные комплексы',
                    list: ['Система доступа жильцов', 'Пропускная система для автомобилей по гос. номерам']
                },
                {
                    title: 'Режимные объекты',
                    list: ['Разноуровневая пропускная система', 'Контроль и учёт доступа']
                },
                {
                    title: 'Торговые, развлекательные, гостиничные комплексы',
                    list: ['Бесконтактная оплата услуг', 'Доступ посетителей и сотрудников территории']
                },
                {
                    title: 'Транспортная инфраструктура',
                    list: ['Бесконтактная оплата проезда', 'Учёт пассажиропотока', 'Контроль доступа сотрудников к объектам особой безопасности']
                },
                {
                    title: 'Автомобильные парковочные стоянки',
                    list: ['Бесконтактный доступ по гос. номеру или биометрии', 'Учёт въездов и выездов']
                },
            ]
        },

        terminal: {
            title: 'Терминалы',
            list: [
                {
                    title: 'INOFACE',
                    text: 'Терминал для автоматизации доступа на объект по биометрии',
                },
                {
                    title: 'INOFACE PARK-1',
                    text: 'Терминал для автоматизации доступа автомобилей на парковку',
                },
            ],
        },

        ControlTheSystem: {
            title: 'Управляйте системой из любой точки мира',
            description: 'Войти в программу СКУД можно с любого компьютера, подключённого к интернету. Для этого достаточно помнить свой логин и пароль',
            list: [
                {
                    text: 'Отслеживайте активность сотрудников с помощью журнала проходов',
                    src: '/time-icon.svg'
                },
                {
                    text: 'Имейте доступ к информации о всех неудачных попытках прохода, в том числе и фотографию',
                    src: '/error-circle-icon.svg'
                },
                {
                    text: 'Выдавайте временные и гостевые пропуски',
                    src: '/profile_icon.svg'
                },
                {
                    text: 'Создавайте группы сотрудников с индивидуальными правами',
                    src: '/group-user-icon.svg'
                },
            ]
        }
    },

    face2pay: {
        header: {
            subtitle: 'Биометрическая система банковского эквайринга, позволяющая производить оплату товаров и услуг с помощью лиц',
        },

        videoSection: {
            description: 'Оплата через сервис Face2Pay'
        },

        advantageSection: {
            list: [
                {
                    title: 'Ускоряет обслуживание на кассах до 30%'
                },
                {
                    title: 'Снижает стоимость банковского эквайринга'
                },
                {
                    title: 'Полное соответствие ФЗ-54 «О применении контрольно-кассовой техники»'
                },
                {
                    title: 'Персонализирует рекламу на экране терминала'
                },
            ]
        },

        usedTerminal: {
            title: 'Используемый терминал',
            description: 'Каждая торговая точка должна быть оснащена минимум одним терминалом'
        }
    },

    face2Security: {
        header: {
            subtitle: 'Контроль безопасности рабочего места по биометрии лица пользователя, находящегося перед компьютером, и контроль его производительности',
        },

        videoSection: {
            description: 'Как работает система Faсe2Security'
        },

        advantageSection: {
            list: [
                {
                    title: 'Авторизация пользователей на рабочих местах'
                },
                {
                    title: 'Подтверждение важных операций с помощью биометрии'
                },
                {
                    title: 'Интеграция с системами безопасности'
                },
                {
                    title: 'Исключение подглядываний и подбора пароля'
                },
                {
                    title: 'Фиксация в журнале и предотвращение несанкционированного доступа к рабочему месту'
                },
            ]
        },

        usedTerminal: {
            title: 'Оборудование',
            description: 'Для использования технологии Face2Security достаточно встроенной веб-камеры компьютера'
        }


    },

    face2Loyalty: {
        header: {
            subtitle: 'Программа лояльности на основе распознавания лица',
        },

        videoSection: {
            description: 'Использование в кафе Cava'
        },

        advantageSection: {
            list: [
                {
                    title: 'Рост скорости обслуживания',
                    text: 'При регистрации не требуется заполнять анкету, достаточно посмотреть в камеру'
                },
                {
                    title: 'Высокая скорость регистрации',
                    text: 'Клиент не тратит время на предъявление карты, а просто смотрит в камеру'
                },
                {
                    title: 'Быстро подключиться',
                    text: 'Подходит к большинству кассовых терминалов, таких как iiko и r-keeper'
                },
            ]
        },

        usedTerminal: {
            title: 'Оборудование',
            list: [
                {
                    title: 'Если у вас есть \nFace2Pay',
                    text: 'Программа подключается к биометрическому терминалу',
                },
                {
                    title: 'Если вы не подключены \nк Face2Pay',
                    text: 'Для работы Face2Loyalty подойдет любой планшет',
                },
            ]
        }
    },

    errorPage: {
        title: 'Страница не найдена',
        text: 'К сожалению, такой страницы не существует.\nВероятно, она была удалена или её здесь никогда не было',
        goBack: 'Вернуться обратно'
    },
};